export const Security = () => (
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_0_195"
      style={{'mask-type': 'alpha'}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="40"
      height="40"
    >
      <rect width="40" height="40" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_0_195)">
      <path
        d="M20.0001 36.6667C16.1204 35.6945 12.9283 33.4514 10.4238 29.9375C7.91911 26.4236 6.66675 22.5556 6.66675 18.3333V8.33334L20.0001 3.33334L33.3334 8.33334V18.3333C33.3334 22.5556 32.0811 26.4236 29.5763 29.9375C27.0719 33.4514 23.8798 35.6945 20.0001 36.6667ZM20.0001 33.7779C22.8612 32.8518 25.2362 31.1133 27.1251 28.5625C29.014 26.0117 30.1205 23.1575 30.4447 20H20.0001V6.31959L9.44466 10.2638V18.3333C9.44466 18.6575 9.44925 18.9307 9.45841 19.1529C9.46758 19.3752 9.49994 19.6575 9.5555 20H20.0001V33.7779Z"
        fill="#FBA346"
      />
    </g>
  </svg>
);
